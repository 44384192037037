import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import MainLayout from './components/MainLayout';
import { ParallaxProvider } from 'react-scroll-parallax';

import Home from './pages/Home';
import Talent from './pages/Talent';
import TalentForm from './pages/TalentForm';
import ThankYou from './pages/ThankYou';
import PageNotFound from './pages/PageNotFound';
import TermService from './pages/TermService';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter>
                <Routes>
                    <Route element={<MainLayout />}>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/talent' element={<Talent/>}/>
                        <Route path='/form-talent' element={<TalentForm/>}/>
                        <Route path='/terima-kasih' element={<ThankYou/>}/>
                        <Route path='/ketentuan-layanan' element={<TermService/>}/>
                        <Route path='/kebijakan-privasi' element={<PrivacyPolicy/>}/>
                        <Route path='*' element={<PageNotFound/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ParallaxProvider>
    )
}

export default App;

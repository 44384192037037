// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page404 {
  height: 100vh;
  margin-top: -106px;
  background: linear-gradient(180deg, rgb(254, 254, 242) 0%, rgb(243, 254, 242) 70%, rgba(255, 255, 255, 0) 98%);
}
.page404 .bg-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
}
.page404 .bg-header h5 {
  font-size: 6rem;
  text-align: center;
  font-family: "Lora-Bold";
}`, "",{"version":3,"sources":["webpack://./src/scss/404.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,8GAAA;AACJ;AACI;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;EACA,gBAAA;AACN;AACM;EACE,eAAA;EACA,kBAAA;EACA,wBAAA;AACR","sourcesContent":[".page404{\n    height: 100vh;\n    margin-top: -106px;\n    background: linear-gradient(180deg, rgb(254, 254, 242) 0%, rgb(243, 254, 242) 70%, rgba(255, 255, 255, 0) 98%);\n    \n    .bg-header{\n      position: absolute;\n      left: 0;\n      right: 0;\n      top: 50%;\n      transform: translateY(-50%);\n      background: none;\n  \n      h5{\n        font-size: 6rem;\n        text-align: center;\n        font-family: 'Lora-Bold';\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from "react"
import http from "../components/utils/Request"
import { Container } from "react-bootstrap"

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>({})

    const loadData = () => {
        setLoading(true)

        http.get(`public/term`, {
            params: {
                slug: "kebijakan-privasi"
            }
        }).then((res) => {
            if(res.data.status){
                setData(res.data.data)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    return(
        <Container className="pt-5 mb-5">
            <div className="header text-center text-black">{data?.title}</div>
            <div className="mt-5" dangerouslySetInnerHTML={{__html: data?.content}} />
        </Container>
    )
}

export default PrivacyPolicy
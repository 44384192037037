import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import logo from "./../assets/img/logo.png";
import { useLocation } from "react-router-dom";

const MainHeader = () => {
    const [scroll, setScroll] = useState(false);
    const location =  useLocation();

    const scrollTo = (id:string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY >= 60);
        });
    });

    return(
        <header className={`headerMain ${scroll ? "navresize" : "naviddle"} fixed-top`}>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} className="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <FiMenu/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {
                            location.pathname === "/" ? (
                                <Nav className="ms-auto align-items-center gap-4">
                                    <Nav.Link href="#" onClick={() => scrollTo("about-us")}>Tentang Kami</Nav.Link>
                                    <Nav.Link href="#" onClick={() => scrollTo("call-us")}>Hubungi Kami</Nav.Link>
                                    <Nav.Link href="/talent" className={`${location.pathname.indexOf('/talent') > -1?'active':''}`}>Talent</Nav.Link>
                                </Nav>
                            ) : (
                                <Nav className="ms-auto align-items-center gap-4">
                                    <Nav.Link href="/">Beranda</Nav.Link>
                                    <Nav.Link href="/talent" className={`${location.pathname.indexOf('/talent') > -1?'active':''}`}>Talent</Nav.Link>
                                </Nav>
                            )
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default MainHeader
import { Button, Spinner } from "react-bootstrap"

const ButtonLoading = (props:any) => {
    return(
        <Button disabled={props.disabled?props.disabled:props.loading==="true"?true:false} {...props}>
            {
                props.loading === "true" ?
                <div>
                    <Spinner animation="border" size="sm" />
                    <span> &nbsp; Loading</span>
                </div>
                :
                props.children
            }
        </Button>
    )
}

export default ButtonLoading
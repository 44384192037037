import { Button, Col, Container, Row } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import { FaChevronRight } from "react-icons/fa"
import { Link } from "react-router-dom"

const ForTalent = () => {
    return(
        <div className="mt-5">
            <div className="for-talent-wrapper">
                <Row>
                    <Col md={7} style={{padding:0}}>
                        <div className="px-5 py-5 ms-5">
                            <ScrollAnimation animateIn='bounce' animateOnce={true}>
                                <strong className="text-brand2 text-center-mobile">UNTUK TALENT</strong>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <div className="header mt-4">Kami Membutuhkan Talent Kamu</div>
                                <p className="mt-4">Kami lagi nyari temen yang asik dan enerjik buat gabung di tim penjualan untuk membantu client besar kami, kalau kami puunya passion yang tinggi, gaul, suka tantangan dan doyan hasil.</p>
                                <p>Yuk kita bangun jadi tim yang solid !</p>
                                
                                <div style={{height:150}}/>
                                <hr/>
                            </ScrollAnimation>
                            <Row>
                                <Col xs={6} md={4}>
                                    <strong>KASBON</strong>
                                    <p className="mt-2">Memberi kesempatan talent mendapatkan sebagian penghasilannya di awal untuk mendukung kebutuhan domestik talent sebelum bekerja.</p>
                                </Col>
                                <Col xs={6} md={4}>
                                    <strong>TASK MANAGEMENT</strong>
                                    <p className="mt-2">Mengatur jadwal dan melaporkan hasil pekerjaan pada Team Leader.</p>
                                </Col>
                                <Col xs={6} md={4}>
                                    <strong>REKAP</strong>
                                    <p className="mt-2">Talent dapat melihat pekerjaan, perekrut, dan gaji sebelumnya.</p>
                                </Col>
                            </Row>

                            <div className="mt-5">
                                <a href="/form-talent">
                                    <Button variant="light"><strong>Daftar Sekarang &nbsp; <FaChevronRight/></strong></Button>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col md={5} style={{padding:0}}>
                        <div className="img-for-talent"/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ForTalent
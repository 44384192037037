import { WithContext as ReactTags } from "react-tag-input"

const KeyCodes = {
    comma: 188,
    enter: 13,
}
const delimiters = [KeyCodes.comma, KeyCodes.enter]

interface Props {
    tags: any,
    onChange: (args: any) => any
}

const InputTag = ({ tags, onChange }: Props) => {

    const handleDelete = (i:number) => {
        onChange(tags.filter((tag:any, index:number) => index !== i))
    }

    const handleAddition = (tag:any) => {
        onChange([...tags, tag]);
    }

    return(
        <div className="input-tag-wrapper">
            <ReactTags
                autoFocus={false}
                placeholder="Enter untuk menambah"
                tags={tags}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="top"
                autocomplete
                allowDragDrop={false}
            />
        </div>
    )
}

export default InputTag
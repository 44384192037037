import { useState } from "react"
import axios from "axios"
import SweetAlert from "./SweetAlert"
import { Button, Card, Form, Spinner } from "react-bootstrap"
import { FaFile } from "react-icons/fa"

interface Props {
    value: string,
    accept: string,
    onChange: (args: any) => any,
    desc: string,
    maxSize?: number
}

const FileUploader = ({
    accept = "",
    value = "",
    onChange,
    desc = "",
    maxSize
}: Props) => {
    const [loading, setLoading] = useState(false)

    const handleChange = (e:any) => {
        setLoading(true)

        const file = e.target.files[0];

        if (maxSize) {
            if (file.size > maxSize * 1000) {
                SweetAlert({
                    icon: 'error',
                    text: 'Ukuran file tidak cocok'
                })
                setLoading(false)
                return false
            }
        }

    const formData = new FormData();

    formData.append("file", file);
    formData.append("email", process.env.REACT_APP_CDN_EMAIL?process.env.REACT_APP_CDN_EMAIL:"");
    formData.append("password", process.env.REACT_APP_CDN_PASSWORD?process.env.REACT_APP_CDN_PASSWORD:"");

    axios({
        method: "post",
        url: process.env.REACT_APP_CDN_URL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
        if (res.data.status) {
            onChange(res.data.data.url)
        } else {
            SweetAlert({
                icon: 'error',
                text: res.data.error
            })
        }
    }).catch((error) => {
        if (error.response) {
            SweetAlert({
                icon: 'error',
                text: error.response.data.message
            })
        } else {
            SweetAlert({
                icon: 'error',
                text: 'Oops.. Something Wrong!'
            })
        }
    }).finally(() => {
        setLoading(false)
    })
  }


  if (loading) {
    return (
        <div className="mt-2 d-flex flex-row align-items-center gap-2">
            <Spinner size="sm"/>
            <div>Uploading file...</div>
        </div>
    )
  }

  return (
    <>
        {
            !value ? (
                <>
                    <Form.Control
                        type="file"
                        accept={accept}
                        onChange={handleChange}
                    />
                    { desc && <Form.Text muted>{desc}</Form.Text> }
                </>
            ) : (
                <Card className="p-2">
                    <Card.Body>
                        <div className="row align-items-center">
                        <div className="col-sm-4 col-md-2">
                            <div className="text-center"><FaFile size={30}/></div>
                        </div>
                        <div className="col-sm-8 col-md-10">
                            <div><a href={value} target="_blank" rel="noreferrer">{value}</a></div>
                            <Button variant="danger" size="sm" className="mt-2" onClick={() => onChange("")}>Ganti File</Button>
                        </div>
                        </div>
                    </Card.Body>
                </Card>
            )
        }
    </>
  )
}

export default FileUploader
import { Outlet, useLocation } from "react-router-dom";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import ScrollToTop from "./ScrollToTop";
import { useEffect } from "react";

const MainLayout = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [pathname])

    return (
        <div className="main-layout-container">
            <MainHeader />
            <div className="outlet-wrapper">
                <Outlet />
            </div>
            <MainFooter />
            <ScrollToTop/>
        </div>
    );
};

export default MainLayout;

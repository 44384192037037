import { Col, Container, Row } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"

const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false
  }

const Partner = () => {
    return(
        <Container className="mt-5 mb-5 pt-5 pb-5">
            <ScrollAnimation animateIn='bounce' animateOnce={true} className="text-center">
                <strong className="text-brand">PARTNER</strong>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="header mt-4 text-black text-center">Partner Kami Yang Berharga</div>
            </ScrollAnimation>

            <Slider {...settings}>
                <div>
                    <Row className="mt-2 mb-5 partner-list justify-content-center">
                        {[...Array(12)].map((x, i) => (
                            <Col key={`partner-${i}`} xs={3} md={2} lg={2} className="mt-2">
                                <img src={require(`../assets/img/partner/${i+1}.png`)} className="hover-zoom" alt="" />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div>
                    <Row className="mt-2 mb-5 partner-list justify-content-center">
                        {[...Array(12)].map((x, i) => (
                            <Col key={`partner-${i+7}`} xs={3} md={2} lg={2} className="mt-2">
                                <img src={require(`../assets/img/partner/${i+7}.png`)} alt="" />
                            </Col>
                        ))}
                    </Row>
                </div>
                <div>
                    <Row className="mt-2 mb-5 partner-list justify-content-center">
                        {[...Array(8)].map((x, i) => (
                            <Col key={`partner-${i+14}`} xs={3} md={2} lg={2} className="mt-2">
                                <img src={require(`../assets/img/partner/${i+14}.png`)} alt="" />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Slider>
        </Container>
    )
}

export default Partner
import React, {useState} from 'react'
import { FaArrowUp } from "react-icons/fa";

const ScrollToTop = () =>{  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500){
      setVisible(true)
    } 
    else if (scrolled <= 500){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className='btn-scroll-wrap' style={{display: visible ? 'inline' : 'none'}}>
      <div 
        className='btn-scroll'
        onClick={scrollToTop}
      >
        <FaArrowUp/>
      </div>
      <div className='txt'>Ke Atas</div>
    </div>
  );
}
  
export default ScrollToTop;
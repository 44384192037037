import { useEffect } from "react";
import { Button, Container, Spinner } from "react-bootstrap"
import { useLocation } from "react-router-dom"

const ThankYou = () => {
    const { state } = useLocation();

    useEffect(() => {
        if(!state){
            window.location.href = "/"
        }
    }, [state])

    if(!state) {
        return(
            <div className="thank-container">
                <Spinner/>
            </div>
        )
    }

    return(
        <div className="thank-container">
            <Container>
                <div className="text-center">
                    {
                        state?.from === "talent-form" ? (
                            <div className="header text-brand">Terima Kasih Telah Mendaftar<br/>Sebagai Talent</div>
                        ) : (
                            <div className="header text-brand">Terima Kasih Telah Menghubungi Kami</div>
                        )
                    }
                    <div className="title mt-4">Kami Akan Segera Menghubungi Anda</div>
                    <a href="/">
                        <Button variant="brand" className="mt-5"><strong>Kembali Ke Beranda</strong></Button>
                    </a>
                </div>
            </Container>
        </div>
    )
}

export default ThankYou
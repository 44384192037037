import { Button, Card, Col, Container, Row } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import imgSpg from "../assets/img/spg.png"
import imgMerchandiser from "../assets/img/merchandiser.png"
import imgUsher from "../assets/img/usher.png"
import imgMc from "../assets/img/mc.png"
import { FaChevronRight } from "react-icons/fa"
import { NavLink } from "react-router-dom"

const OurTalent = () => {
    return(
        <div className="pt-5 pb-5 mt-5">
            <Container>
                <Row>
                    <Col sm={12} md={8} lg={4} className="mb-3">
                        <ScrollAnimation animateIn='bounce' animateOnce={true}>
                            <div className="text-center-mobile">
                                <strong className="text-brand">TALENT</strong>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                            <div className="header mt-4 text-black text-center-mobile">Talent Kami Yang Sudah Bergabung</div>
                            <div className="text-center-mobile mt-5">
                                <NavLink to="/talent">
                                    <Button variant="brand"><strong>Jelajahi Talent &nbsp; <FaChevronRight/></strong></Button>
                                </NavLink>
                            </div>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={6} md={2} lg={2} className="mb-4">
                        <ScrollAnimation animateIn='fadeInRight' delay={0} animateOnce={true}>
                            <Card>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    <img src={imgSpg} alt="" width={"30%"}/>
                                    <div className="header mt-4"><strong>1340</strong></div>
                                    <p className="mt-2" style={{fontSize:12}}>SPG</p>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={6} md={2} lg={2} className="mb-4">
                        <ScrollAnimation animateIn='fadeInRight' delay={100} animateOnce={true}>
                            <Card>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    <img src={imgMerchandiser} alt="" width={"30%"}/>
                                    <div className="header mt-4"><strong>970</strong></div>
                                    <p className="mt-2" style={{fontSize:12}}>Merchandiser</p>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={6} md={2} lg={2} className="mb-4">
                        <ScrollAnimation animateIn='fadeInRight' delay={200} animateOnce={true}>
                            <Card>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    <img src={imgUsher} alt="" width={"30%"}/>
                                    <div className="header mt-4"><strong>970</strong></div>
                                    <p className="mt-2" style={{fontSize:12}}>Usher</p>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={6} md={2} lg={2} className="mb-4">
                        <ScrollAnimation animateIn='fadeInRight' delay={300} animateOnce={true}>
                            <Card>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    <img src={imgMc} alt="" width={"30%"}/>
                                    <div className="header mt-4"><strong>970</strong></div>
                                    <p className="mt-2" style={{fontSize:12}}>MC</p>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurTalent
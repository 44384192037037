import { useEffect, useState } from "react"
import { Card, Col, Container, Form, Row } from "react-bootstrap"
import ButtonLoading from "../components/ButtonLoading"
import InputTag from "../components/InputTag"
import imgPhotogrid from "../assets/img/photogrid-example.jpg"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ImageUploader from "../components/ImageUploader"
import SweetAlert from "../components/SweetAlert"
import DateRangePicker from "react-bootstrap-daterangepicker"
import moment from "moment"
import http from "../components/utils/Request"
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import FileUploader from "../components/FileUploader"

const TalentForm = () => {
    const navigate = useNavigate()
    const [form, setForm] = useState<any>({
        fullname: "",
        email: "",
        age: "",
        gender: "",
        birth_date: "",
        domicile: "",
        socmed_url : "",
        phone: "",
        position: [],
        height: "",
        weight: "",
        experience: [],
        description: "",
        resource: "",
        photo: "",
        agreement: false,
        cv: ""
    })
    const [loading, setLoading] = useState(false)
    const [city, setCity] = useState([])
    const [position, setPosition] = useState([])

    const loadCity = () =>  {
        http.get(`public/city`, {
            params: {
                order: [
                    {
                        field: 'ms_city_name',
                        sort: 'asc'
                    }
                ]
            }
        })
        .then((res) => {
            setCity(res.data.data.map((item:any) => ({ value: item.ms_city_id, label: item.ms_city_name })))
        })
    }

    const loadPosition = () =>  {
        http.get(`public/position_loker`, {
            params: {
                filter: [
                    {
                        field: "status",
                        operator: "=",
                        value: "1"
                    }
                ]
            }
        })
        .then((res) => {
            setPosition(res.data.data)
        })
    }

    const onSubmit = (e:any) => {
        e.preventDefault()

        var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if(mailformat.test(form.email) === false){
            SweetAlert({
                icon: 'error',
                text: "Email tidak valid"
            })
            return false
        }

        if(!form.gender) {
            SweetAlert({
                icon: 'error',
                text: "Jenis Kelamin harus diisi"
            })

            return false
        }

        if(form.position.length === 0) {
            SweetAlert({
                icon: 'error',
                text: "Posisi pekerjaan minimal 1"
            })

            return false
        }

        if(form.experience.length === 0) {
            SweetAlert({
                icon: 'error',
                text: "Pengalaman minimal 1"
            })

            return false
        }

        if(!form.photo) {
            SweetAlert({
                icon: 'error',
                text: "Photogrid harus diisi"
            })

            return false
        }

        if(!form.cv) {
            SweetAlert({
                icon: 'error',
                text: "CV harus diisi"
            })

            return false
        }

        setLoading(true)

        http.post(`public/talent-register`, {
            name: form.fullname,
            email: form.email,
            phone: form.phone,
            resources: form.resource,
            age: form.age,
            height: form.height,
            weight: form.weight,
            experience: form.experience.map((x:any) => x.text).toString(),
            domicile: form.domicile,
            birth_date: form.birth_date,
            socmed_url: form.socmed_url,
            position: form.position.toString(),
            description: form.description,
            photogrid: form.photo,
            gender: form.gender,
            cv: form.cv
        }).then((res) => {
            if (res.data.status) {
                navigate("/terima-kasih", { state: { key: "talent-form" } })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: res.data.mesage
                })
            }
        }).catch((error) => {
            if (error.response) {
                SweetAlert({
                    icon: 'error',
                    text: error.response.data.error
                })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: 'Oops.. Terjadi Kesalahan!'
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        loadCity()
        loadPosition()
    }, [])

    return(
        <div className="pt-5 pb-5">
            <Container>
                <Card>
                    <Card.Header className="bg-brand-opacity"><strong className="text-brand2">FORMULIR PENDAFTARAN</strong>
                        <div className="header mt-4">Pendaftaran Lowongan Pekerjaan TEMA</div>
                        <p className="mt-4">Form ini digunakan untuk melakukan pendataan kandidat yang berminat dan ingin bergabung dengan tim kami. Silahkan isi data  dibawah ini dengan lengkap dan benar.</p>
                    </Card.Header>
                    <Card.Body>
                        <PhotoProvider maskOpacity={.7}>
                            <Form onSubmit={onSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Nama Lengkap<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Nama Lengkap"
                                                value={form.fullname}
                                                onChange={(event) => setForm((state:any) => ({...state, fullname: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Email<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Email"
                                                value={form.email}
                                                onChange={(event) => setForm((state:any) => ({...state, email: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Jenis Kelamin<span className="text-danger"> *</span></strong></Form.Label>
                                            <div className="d-flex flex-row gap-4">
                                                <Form.Check
                                                    type={"radio"}
                                                    label={"Laki-laki"}
                                                    checked={form.gender === "male"}
                                                    onClick={(event) => setForm((state:any) => ({...state, gender: "male"}))}
                                                />
                                                <Form.Check
                                                    type={"radio"}
                                                    label={"Perempuan"}
                                                    checked={form.gender === "female"}
                                                    onClick={(event) => setForm((state:any) => ({...state, gender: "female"}))}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Tanggal Lahir<span className="text-danger"> *</span></strong></Form.Label>
                                            <DateRangePicker
                                                initialSettings={{
                                                    singleDatePicker: true,
                                                    autoUpdateInput: false,
                                                    showDropdowns: true,
                                                    locale: {
                                                        applyLabel: "Terapkan",
                                                        cancelLabel: "Batal"
                                                    }
                                                }}
                                                onCallback={(start) => setForm((state:any) => ({...state, birth_date: moment(start).format("YYYY-MM-DD")}))}
                                            >
                                                <Form.Control
                                                    placeholder="Tanggal Lahir"
                                                    defaultValue={form.birth_date}
                                                    disabled={loading}
                                                    required
                                                />
                                            </DateRangePicker>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Umur<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Umur"
                                                value={form.age}
                                                onChange={(event) => setForm((state:any) => ({...state, age: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Domisili<span className="text-danger"> *</span></strong></Form.Label>
                                            <Select
                                                placeholder="Pilih Domisili" 
                                                options={city} 
                                                onChange={(selected:any) => setForm((state:any) => ({...state, domicile: selected.label}))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Instagram URL/Facebook URL<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan URL"
                                                value={form.socmed_url}
                                                onChange={(event) => setForm((state:any) => ({...state, socmed_url: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">No. Handphone (WA)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan No. Handphone (WA)"
                                                value={form.phone}
                                                onChange={(event) => setForm((state:any) => ({...state, phone: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Posisi Pekerjaan Apa Yang Kamu Cari?<span className="text-danger"> *</span></strong></Form.Label>
                                            {
                                                position.map((item:any, index:number) => (
                                                    <Form.Check
                                                        key={`${item}`}
                                                        type={"checkbox"}
                                                        label={item.position_loker_name}
                                                        onClick={() => {
                                                            const check = form.position.findIndex((x:any) => x === item.position_loker_name)
                                                            let positionTemp = [...form.position]
                                                            if(check >= 0){
                                                                setForm((state:any) => ({...state, position: positionTemp.splice(check, 1)}))
                                                            }else{
                                                                setForm((state:any) => ({...state, position: [...positionTemp, item.position_loker_name]}))
                                                            }
                                                        }}
                                                    />
                                                ))
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Tinggi Badan (cm)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Tinggi Badan"
                                                value={form.height}
                                                onChange={(event) => setForm((state:any) => ({...state, height: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Berat Badan (kg)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Berat Badan"
                                                value={form.weight}
                                                onChange={(event) => setForm((state:any) => ({...state, weight: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Darimana Kamu Mendapat Info Lowongan Ini?<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Data"
                                                value={form.resource}
                                                onChange={(event) => setForm((state:any) => ({...state, resource: event.target.value}))}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Pengalaman (Posisi)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted> Tekan Enter Untuk Menambahkan</Form.Text>
                                            <InputTag
                                                tags={form.experience}
                                                onChange={(tags) => setForm((state:any) => ({ ...state, experience: tags}))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Deskripsikan Diri Anda<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted> Pengalaman, Hobi, Keahlian, dll</Form.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                placeholder="Masukan Deskripsi"
                                                value={form.description}
                                                onChange={(event) => setForm((state:any) => ({...state, description: event.target.value}))}
                                                disabled={loading}
                                                rows={7}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Photogrid (Nama/TB/BB)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted> Contoh: (Nabila/163/55)</Form.Text>
                                            <br/>
                                            <div className="d-flex flex-row flex-wrap gap-3">
                                                <PhotoView src={imgPhotogrid}>
                                                    <img src={imgPhotogrid} width="200px" className="pointer" alt=""/>
                                                </PhotoView>
                                                <div style={{flex:1, height: "200px"}}>
                                                    <ImageUploader
                                                        value={form.photo}
                                                        onChange={(img) => setForm((state:any) => ({ ...state, photo: img }))}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">CV<span className="text-danger"> *</span></strong></Form.Label>
                                            <FileUploader
                                                accept=".pdf,.doc,.docx,.txt,.pdfx"
                                                value={form.cv}
                                                onChange={(url) => setForm((state:any) => ({ ...state, cv: url }))}
                                                desc="(Type of file: pdf, doc, docx, txt, pdfx)"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <div className="d-flex flex-row gap-2">
                                            <Form.Check
                                                type={"checkbox"}
                                                checked={form.agreement}
                                                onChange={() => setForm((state:any) => ({...state, agreement: !form.agreement}))}
                                                required
                                            />
                                            <p className="flex-fill">
                                                Dengan ini saya menyetuji <a href="/ketentuan-layanan" target="_blank" className="text-brand">Ketentuan Layanan</a> & <a href="/kebijakan-privasi" target="_blank" className="text-brand">Kebijakan Privasi</a> yang berlaku.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={12} className="mt-4">
                                        <ButtonLoading type="submit" variant="brand" loading={loading.toString()}><strong>Kirim</strong></ButtonLoading>
                                    </Col>
                                </Row>
                            </Form>
                        </PhotoProvider>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default TalentForm
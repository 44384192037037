import { Card, Col, Container, Row } from "react-bootstrap";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import imgTugas from "../assets/img/clipboard.png";
import imgRekap from "../assets/img/business-report.png"
import ScrollAnimation from "react-animate-on-scroll";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

const settingsFirst = {
    dots: false,
    autoplay: false,
    swipe: false,
    arrows: false,
}

const settings = {
    dots: true,
    autoplay: true,
    infinite: false,
    swipe: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const Service = () => {
    let firstRef = useRef<any>(null)
    const [number, setNumber] = useState(0)

    useEffect(() => {
        if(number === 0) {
            firstRef.current.slickGoTo(0)
        }else{
            firstRef.current.slickGoTo(1)
        }
    }, [number])

    return(
        <Container className="service pt-5 pb-5">
            <Row>
                <Col sm={12} md={5} lg={5} className="mb-3">
                    <ScrollAnimation animateIn='bounce' animateOnce={true}>
                        <strong className="text-brand">LAYANAN KAMI</strong>
                    </ScrollAnimation>
                    <Slider ref={firstRef} {...settingsFirst}>
                        <div>
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <div className="header mt-4 text-black">Untuk Principal</div>
                                <p className="mt-4">Principal dapat mengatur pekerjaan, memantau performa, dan melihat laporan hasil akhir, termasuk penjualan.</p>
                            </ScrollAnimation>
                        </div>
                        <div>
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <div className="header mt-4 text-black">Untuk Talent</div>
                                <p className="mt-4">Talent melamar, direkrut, menerima pembayaran awal, dan melihat riwayat.</p>
                            </ScrollAnimation>
                        </div>
                    </Slider>
                    <div className="mt-5 d-flex flex-row gap-3">
                        <div className={`arrow-service ${number === 0 ? "disabled" : ""}`} onClick={() => setNumber(0)}>
                            <FaChevronLeft/>
                        </div>
                        <div className={`arrow-service ${number === 1 ? "disabled" : ""}`} onClick={() => setNumber(1)}>
                            <FaChevronRight/>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={7} lg={7}>
                    <div className="bg-service">
                        <div className="ps-4 py-5 pb-0">
                            <ScrollAnimation animateIn='fadeInRight' delay={100} animateOnce={true}>
                                {
                                    number === 0 ? (
                                        <div className="fade-in-text">
                                            <Slider {...settings}>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgTugas} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>Tugas</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>Mengatur jumlah pekerjaan, memantau performa, dan mendapatkan laporan hasil akhir (termasuk sales)</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgRekap} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>Rekap</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>Principal dapat mencari talent yang direkrut sebelumnya dan dapat melihat seluruh laporan dan hasil performa talent. Terdapat tombol ‘rekrut lagi’ untuk principal yang ingin merekrut ulang.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Slider>
                                        </div>
                                    ) : (
                                        <div className="fade-in-text">
                                            <Slider {...settings}>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgTugas} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>Lowongan</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>Mencari pekerjaan, melamar, direkrut, payment.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgTugas} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>Kasbon</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>Yang memberi kesempatan untuk mendapatkan sebagian penghasilannya di awal untuk mendukung kebutuhan domestik talent sebelum bekerja. </p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgTugas} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>Rekap</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>Talent dapat melihat pekerjaan, principal, dan gaji sebelumnya.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="p-2">
                                                    <Card style={{ height:"100%" }}>
                                                        <Card.Body>
                                                            <img src={imgTugas} alt="" width={"30%"}/>
                                                            <div className="title mt-4"><strong>TASERBA (TEMA SERBA ADA)</strong></div>
                                                            <p className="mt-2" style={{fontSize:12}}>swalayan online untuk para talent berbelanja kebutuhan sehari-hari dengan harga yang lebih rendah dari pasaran. Talent juga dapat berbelanja menggunakan poin yang mereka dapat dari hasil kerja mereka</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Slider>
                                        </div>
                                    )
                                }
                            </ScrollAnimation>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Service;
import { Col, Container, Row } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import { Parallax } from "react-scroll-parallax";

const Product = () => {
    return(
        <Container className="relative">
            <div className="product p-5">
                <Parallax speed={-20} className="video-parallax-2"/>
                <Parallax speed={10} className="video-parallax-3"/>
                <ScrollAnimation animateIn='bounce' animateOnce={true} className="text-center">
                    <strong className="text-brand">PRODUK KAMI</strong>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                    <div className="header mt-4 text-black text-center">Membangun Kerangka Manajemen Dari Dalam</div>
                    <p className="text-center mt-4">Membangun kerangka kerja manajemen menciptakan sistem terstruktur untuk pengambilan keputusan, operasi, strategi unggul, memanfaatkan peluang, dan fokus pelanggan.</p>
                </ScrollAnimation>
                <div className="text-center mt-5 video-wrapper">
                    <video autoPlay muted loop>
                        <source src={require("../assets/video/video.webm")} type="video/webm" />
                    </video>
                </div>

                <div className="mt-4">
                    <Row>
                        <Col sm={6} className="mt-2">
                            <div className="text-center text-black"><strong>Aplikasi Mobile</strong></div>
                            <p className="text-center mt-3">Membangun kerangka kerja manajemen dari dalam melibatkan pembuatan sistem terstruktur yang memandu pengambilan keputusan, operasi, dan tata kelola organisasi secara keseluruhan dengan memanfaatkan sumber daya dan wawasan internal.</p>
                        </Col>
                        <Col sm={6} className="mt-2">
                            <div className="text-center text-black"><strong>Sistem Pemantauan Aktivitas</strong></div>
                            <p className="text-center mt-3">Mengembangkan strategi yang unggul untuk tetap unggul dalam persaingan. Memanfaatkan peluang yang mudah untuk mengidentifikasi nilai perkiraan. Memvisualisasikan konvergensi yang diarahkan pada pelanggan.</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default Product
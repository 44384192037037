import { useRef, useState } from "react"
import defaultImage from "../assets/img/talent-default.png"
import { FiUpload } from "react-icons/fi"
import axios from "axios"
import { Spinner } from "react-bootstrap"
import { MdModeEdit } from "react-icons/md"
import { FaTrashCan } from "react-icons/fa6"
import SweetAlert from "./SweetAlert"

interface Props {
    value: string,
    onChange: (args: any) => any
}

const ImageUploader = ({ 
    value,
    onChange
}: Props) => {
    const uploadInputRef = useRef<any>(null)
    const [loading, setLoading] = useState(false)

    const uploadFileHandler = async (e:any) => {
        setLoading(true)

        let formData:any = new FormData();

        formData.append("file", e.target.files[0]);
        formData.append("email", process.env.REACT_APP_CDN_EMAIL);
        formData.append("password", process.env.REACT_APP_CDN_PASSWORD);

        axios({
            method: "post",
            url: process.env.REACT_APP_CDN_URL,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            if (res.data.status) {
                uploadInputRef.current.value = null
                onChange(res.data.data.url)
            } else {
                SweetAlert({
                    icon: 'error',
                    text: res.data.error
                })
            }
        }).catch((error) => {
            if (error.response) {
                SweetAlert({
                    icon: 'error',
                    text: error.response.data.message
                })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: 'Oops.. Terjadi Kesalahan!'
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return(
        <>
            <input
                ref={uploadInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                multiple
                onChange={uploadFileHandler}
            />

            <div className="image-uploader-container">
                <div
                    className="image-uploader-bg singleback"
                    style={{
                        backgroundImage: `url(${value ? value : defaultImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />

                {
                    !value ? (
                        <div 
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                        >
                            {
                                !loading && (
                                    <div
                                        style={{
                                            backgroundColor:"#fff",
                                            padding: 15,
                                            borderRadius: 10,
                                            cursor: "pointer"
                                        }}
                                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                    >
                                        <div 
                                            style={{
                                                width: 50,
                                                height: 50,
                                                backgroundColor: "#eee",
                                                borderRadius: "50%",
                                                margin: "auto",
                                                display: "flex",
                                                justifyContent:"center",
                                                alignItems:"center"
                                            }}
                                        >
                                            <FiUpload size={20}/>
                                        </div>
                                        <div className="mt-3"><strong>Upload Photo</strong></div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <>
                            <img 
                                src={value} 
                                alt=""
                            />

                            <div
                                style={{
                                    position: "absolute",
                                    top: 15,
                                    left: 15,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 8
                                }}
                            >
                                <div 
                                    style={{
                                        backgroundColor: "#fff",
                                        width: 34, 
                                        height: 34,
                                        borderRadius: 8,
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}
                                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                >
                                    <MdModeEdit size={20} color="#007bff"/>
                                </div>
                                <div 
                                    style={{
                                        backgroundColor: "#fff",
                                        width: 34, 
                                        height: 34,
                                        borderRadius: 8,
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}
                                    onClick={() => onChange("")}
                                >
                                    <FaTrashCan size={20} color="#dc3545"/>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    loading &&
                    <div 
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            display: "flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <div
                            style={{
                                backgroundColor:"#fff",
                                padding: 15,
                                borderRadius: 10
                            }}
                        >
                            <div style={{ textAlign:"center" }}>
                                <Spinner style={{ width:30, height: 30 }}/>
                                <div className="mt-2">Upload Photo...</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ImageUploader